import { graphqlQuery } from './services/shopifyApolloClient'
import { fetchProductByHandleQuery, fetchProductByHandleQuerySlim } from './services/graphql/fetchProductQuery'
import { mapProduct } from './mapHelpers'
import fetchProducts from './fetchProducts.js'
import fetchProductsByHandle from "./fetchProductsByHandle";

async function fetchProductByHandle(handle, useCardQuery = false) {
   // console.log("fetchProductByHandle slim?", useCardQuery)
   const { data } = await graphqlQuery({
      query: useCardQuery ? fetchProductByHandleQuerySlim : fetchProductByHandleQuery,
      variables: {
         handle
      },
   });

   if (!data) {
      console.log("(fetchProductByHandle) Handle doesn't exist in Shopify: " + handle);
      return null;
   }

   return data.productByHandle;
}

async function fetchProduct(handle, attachStylesWith = true) {
   let product = await fetchProductByHandle(handle, !attachStylesWith);

   if (!product) {
      return null;
   }

   product = mapProduct(product);

   const relatedTag = product.tags.find(tag => tag.startsWith('related'));

   const relatedProducts = await fetchProducts({ query: 'tag:' + relatedTag});

   let stylesWithProducts = [];

   // We don't want recursion here, hence the flag.
   if (attachStylesWith && product.stylesWith) {
      stylesWithProducts = await fetchProductsByHandle(product.stylesWith)
   }

   // if (attachStylesWith) {
   //    console.log('FETCH PRODUCT: ', product.handle);
   //    console.log('RELATED HANDLES: ', relatedProducts.map(p => p.handle).join(', '))
   //    console.log('STYLES WITH: ', stylesWithProducts.map(p => p.handle).join(', '))
   // }

   return {
      ...product,
      relatedProducts,
      stylesWithProducts
   };

}

// async function fetchProduct(handle) {
//
//    const product = await fetchProductByHandle(handle)
//    const mappedProduct = mapProduct(product)
//
//    if (!mappedProduct) {
//       throw new Error(`can not fetch product with handle: ${handle}`)
//    }
//
//    const relatedTag = `tag:'${mappedProduct.tags.find(tag => tag.includes('related'))}'`
//    const relatedProducts = await fetchProducts({...defaultProductsVariables, query: relatedTag })
//
//
//    const map = {};
//    const stylesWithProducts = [];
//
//    if(mappedProduct.stylesWith) {
//       const products = await fetchProducts();
//       products.forEach(product => {
//          map[product.handle] = product;
//       });
//       mappedProduct.stylesWith.forEach(h => {
//          if(typeof map[h] !== "undefined") {
//             stylesWithProducts.push(map[h]);
//             // console.log(map[h]);
//          }
//       });
//    }
//
//    const mappedProductWithRelated = {
//       ...mappedProduct,
//       relatedProducts,
//       stylesWithProducts
//    }
//
//    return mappedProductWithRelated
// }

export default fetchProduct
